/**NAV*/
.navbar {
  top: 0;
  display: block;
}

nav a {
  margin: 0 10px;
  color: white;
  font-size: 20px;
  transition: all 0.3s ease;
  text-decoration: none;
  font-weight: bold;
}

nav a:hover {
  color: #ccc;
}
