.full-page-section-metod {
    position: relative;
  }

  .descricao-text {
    white-space: pre-wrap;
    font-size: 1.1rem;
    color: #000000;
    text-align: justify;
  }
  
  .descricao:before {
    /* content: "Descrição"; */
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #777;
    text-transform: uppercase;
  }

  .titulo-metodo {
    margin-top: 10px;
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    /* text-transform: uppercase; */
    position: relative;
    padding-bottom: 2rem;
  }
  
  .titulo-metodo:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 40%;
    height: 0.3rem;
    background-color: #f2bd41;
    transform: translateX(-50%);
    border-radius: 0.5rem;
  }
  
/* ******************* */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}
img{
  width: 100%;
}

.title-meto{
  font-size: x-large;
  font-weight: bold;
}
@media (max-width: 7in) {
  .title-meto{
    font-size: smaller;
    font-weight: bold;
  }
}

.icon-metodologia {
  animation: bounce 2s infinite;
}

.img-radar{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button-relacio{
  margin-left: 19.5%;
  margin-top: 16.5%;
  transform: translate(50%,50%);
  border: none !important;
  width: 180px;
  height: 60px;
}
@media (max-width: 7in) {
  .button-relacio{
    margin-left: 20%;
    margin-top: 15.5%;
    transform: translate(50%,50%);
    border: none !important;
    width: 180px;
    height: 60px;
  }
}

.button-desenvol{
  margin-left:-6.5%;
  margin-top:8%;
  transform:translate(50%, 50%);
  border: none !important;
  width:180px;
  height:60px;
}
@media (max-width: 7in) {
  .button-desenvol{
    margin-left:-8%;
    margin-top:8%;
    transform:translate(50%, 50%);
    border: none !important;
    width:180px;
    height:60px;
  }
}

.button-result{
  margin-left:16.5%;
  margin-top:2.5%;
  transform:translate(50%, 50%);
  border: none !important;
  width:180px;
  height:60px;
}
@media (max-width: 7in) {
  .button-result{
    margin-left:15%;
    margin-top:3%;
    transform:translate(50%, 50%);
    border: none !important;
    width:180px;
    height:60px;
  }
}

.button-analise{
  margin-left:29.5%;
    margin-top:24%;
    transform:translate(50%, 50%);
    border: none !important;
    width:180px;
    height:60px;
}
@media (max-width: 7in) {
  .button-analise{
    margin-left:32.5%;
    margin-top:24%;
    transform:translate(50%, 50%);
    border: none !important;
    width:180px;
    height:60px;
  }
}

.button-acao{
  margin-left:52.5%;
    margin-top:20%;
    transform:translate(50%, 50%);
    border: none !important;
    width:180px;
    height:60px;
}
@media (max-width: 7in) {
  .button-acao{
    margin-left:53.5%;
    margin-top:20%;
    transform:translate(50%, 50%);
    border: none !important;
    width:180px;
    height:60px;
  }
}