*{
  font-family: 'Helvetica';
}
.carousel {
    position: relative;
    overflow: hidden;
  }

  
/*Slides*/
.carousel-indicators {
    display: flex;
    justify-content: center;
  }
  
    .bg-image-slide1{
      background-size: cover; 
      background-position: center; 
      position: absolute;
      overflow: hidden;
      height: 100%; 
      filter: blur(5px);
      z-index: -1;
      height: 90vh;
      /* opacity: 1; */
    }

    .slide2-descricao{
        white-space: pre-wrap;
        font-size: 1.2rem;
        color: #ffffff;
        text-align: justify;
        padding: 50px 50px 50px 20px;
    }

    .bg-image-slide3{
      background-size: cover; 
      background-position: center; 
      position: absolute;
      overflow: hidden;
      height: 100%; 
      filter: blur(5px);
      z-index: -1;
      height: 90vh;
      /* opacity: 1; */
    }

    .col-slide3{
        position: relative;
        padding-top: 50px;
    }

    .title-slide3{
        color:#000000;
        font-weight: bold;
        /* text-shadow: 0px 0px 1px #ffffff5e; */
    }
      .text-box-home {
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.518);
        padding: 10px;
        color: rgba(255, 255, 255, 0.343);
        margin-bottom: 10px;
        border-radius: 5px;
        width: 95%;
        transition: transform 0.3s ease;
      }
      
      .text-box-home:hover{
        transform: translateY(-10px);
      }

      .text-box-home-dicas {
        display: flex;
        align-items: center;
        padding: 5px;
        color: rgb(255, 255, 255);
        margin-bottom: 1px;
        border-radius: 5px;
      }

      .icon-img-slide-dicas{
        display: block;
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
      .dicas-img-container{
        border: 2px solid #ffc600;
        padding: 8px;
        width: 10%;
        height: 10%;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
      }

      .icon {
        color: green;
        margin-right: 10px;
        size: 50px;
      }
      
      .text-home {
        margin: 0;
        font-weight: bold;
        margin-left: 20px; 
        color: rgb(8, 8, 8);
      }

      @media (max-width: 7in) {
        .text-home {
          font-size: small;
          margin: 0;
          font-weight: bold;
          margin-left: 20px; 
          color: rgb(8, 8, 8);
        }
      }
      .col-slid2-img{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-color: #111010;
      }
      .col-slid4-img{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-image: url('../../assets/back-slide-dicas.jpeg');
        background-size: auto;
      }
      .col-slide-img-logo{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .col-slide-img-logo img{
        height: 100%;
        max-width: 50%;
      }

      @keyframes slide {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
        }
      }
      
      @keyframes jump {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-20px);
        }
        100% {
          transform: translateY(0);
        }
      }
      
      .slide-animation {
        animation: slide 1.5s forwards;
      }
      
      .jump-animation {
        animation: jump 1.5s infinite;
      }
      
      