.full-page-section-solucoes {
    position: relative;
  }
  
  .card-animada:hover {
    transform: translateY(-10px);
    border-radius: 10px;
    border-color: #00000000 !important;
  }

  .card-animada {
    transition: transform 0.2s ease-out;
    border-color: #00000000 !important;
    height: 300px !important;
  }
  
  @media (max-width: 7in) {
    .card-animada{
      height: auto !important;
    }
  }

  .card-animada-body{
    background-color: rgba(240, 181, 42, 0.894);
    border-radius: 10px;
    border-color: #00000000 !important;
  }
  
  .card-icone-titulo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-icone {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .card-titulo {
    font-weight: bold;
    font-size: 22.5px;
    padding-bottom: 4rem;
    justify-content: center;
  }
  
  @media (min-width: 576px) {
    .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  .cards-global{
    padding: 10px 50px 10px 50px;
    background-color: #ffffff;
    align-items: center;
    border-radius: 0;
  }

  .title-section{
    font-weight: bold;
    margin: 5px;
    padding-bottom: 15px;
    color: #000000 ;
    font-size: x-large;
  }
  .titulo-solucoes {
    margin-top: 10px;
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
    text-align: center;
    /* text-transform: uppercase; */
    position: relative;
    padding-bottom: 2rem;
  }
  
  .titulo-solucoes:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 40%;
    height: 0.2rem;
    background-color: #f2bd41;
    transform: translateX(-50%);
    border-radius: 0.5rem;
  }