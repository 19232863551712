.full-page-section-sobre {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  ;
}

.img-sobre {
  position: relative;
  top: 0;
  right: 0;
  width: 60%;
  height: 70%;
  transform: scale(0.9);
  border-radius: 20px;
  transform: translateX(+50px);
  transition: opacity 4s ease, transform 4s ease;
  opacity: 0;
}

@media (max-width: 768px) {
  .img-sobre {
    width: 100%;
    height: 80%;
  }
}

/*/*/
.text {
  /* padding-top: 50px; */
  padding: 20px;
  font-size: large;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 15px;
  margin-top: 30px;
  opacity: 0;
  transform: translateX(+50px);
  transition: opacity 4s ease, transform 4s ease;
  position: re;
}

.img-sobre.show {
  opacity: 1;
  transform: translateX(0);
}

.text.show {
  opacity: 1;
  transform: translateX(0);
}

.descricao-sobre {
  font-family: 'Poppins';
  white-space: pre-wrap;
  font-size: 0.9rem;
  color: #000000;
  text-align: justify;
  padding: 1rem;
}

.animated-component {
  justify-content: center;
}

.button-sobre {
  /* float: left; */
  font-size: larger;
  font-weight: bold;
  color: white;
  position: relative;
  overflow: hidden;
  padding: 12px 40px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: transform 0.3s ease-out;
}

.button-sobre:hover {
  transform: translateY(-9px);
}

.button-sobre:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  animation: jump 0.5s ease-out;
}

@keyframes jump {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
