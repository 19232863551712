footer{
    background-color: #f0b52a;
    display: block;
    width: 100%;
}

@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0);
    }
  }
img{
    width: 100%;
}

  .icon-contato {
    animation: bounce 0s infinite;
    width: 50px;
    height: 50px;
  }

  .col-contatos{
    text-align: center;
    background-color: #fff6f000;
    margin: 20px;
    border-radius: 20px;
  }

  .contatos-descri{
    border-radius: 15px 0px 0px 15px;
  }

  .contatos-icons{
    border-radius: 0px  15px 15px 0px;
  }

  .siga-nos{
    display: inline-flex;
    margin-top: 1%;
    margin-bottom: 10px;
  }
  .social-icons{
    margin-left: 15px;
  }
  .email{
    font-weight: bold;
    text-decoration: underline;
  }
