*{
  font-family: 'Helvetica';
}
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0px 0px 20px #25d366;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  animation: whatsapp-button-wave 2s infinite;
  animation-timing-function: linear;
}
.whatsapp-icon{
  color: white;
}

.whatsapp-button:hover {
  /* right: -10px; */
  box-shadow: 0px 0px 20px #fff;
}

@keyframes whatsapp-button-wave {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(37, 211, 102, 0.8);
  }
  50% {
    box-shadow: 0px 0px 20px 10px rgba(37, 211, 102, 0.8);
  }
  100% {
    box-shadow: 0px 0px 0px 0px rgba(37, 211, 102, 0.8);
  }
}
